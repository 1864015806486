import React, { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import { IWorkout, PageContext } from '@appTypes';
import styled from '@emotion/styled';
import SEO from '../site/components/SEO';
import { theme } from '../site/styles';
import axios from 'axios';

import { debounce } from 'lodash';
import WorkoutCard from '@components/Cards/v1/WorkoutCard';
import { WHITE } from '@constants';
import { isBrowser } from '@utils';

const { breakpoints } = theme;

const WorkoutsPage: React.FC<PageProps<{}, PageContext>> = ({ pageContext: { locale }, location }) => {
  const [workouts, setWorkouts] = useState<IWorkout[]>([]);
  const [playingVideoIndex, setPlayingVideoIndex] = useState<number | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [nextPage, setNextPage] = useState<string>('');

  useEffect(() => {
    if (isBrowser()) {
      const urlParams = new URLSearchParams(window.location.search);
      const orgId = urlParams.get('orgId');
      if (orgId) {
        getWorkouts(`${process.env.GATSBY_CONTENT_API_URL}/orgs/${orgId}/workouts`);
      } else {
        window.location.href = '/';
      }
    }
  }, []);

  const fetchData = async () => {
    if (isFetching) return;
    setIsFetching(true);

    try {
      await getWorkouts(nextPage);
    } catch (error) {
      console.log(error);
    }

    setIsFetching(false);
  };

  const getWorkouts = async (page: string) => {
    if (page) {
      try {
        const {
          data,
        }: {
          data: {
            data: IWorkout[];
            links: {
              next: string;
            };
          };
        } = await axios.get(page, {
          headers: {
            'X-Api-Key': process.env.GATSBY_CONTENT_API_KEY,
          },
        });

        if (Object.keys(data).length) {
          setNextPage(data.links.next);
          const newWorkouts = [...workouts, ...data.data];
          setWorkouts(newWorkouts);
        } else {
          navigateMain();
        }
      } catch (error) {
        navigateMain();
      }
    }
  };

  const navigateMain = () => {
    if (isBrowser()) {
      window.location.href = '/';
    }
  };

  const handleVideoPlay = (event: Event) => {
    const target = event.target as HTMLButtonElement;
    const cardElement = target.parentElement;
    const index = cardElement && cardElement.getAttribute('data-index');
    if (playingVideoIndex === index) {
      setPlayingVideoIndex(null);
    } else {
      setPlayingVideoIndex(Number(index));
    }
  };

  const handleScroll = debounce(() => {
    const currentScrollY = window.scrollY;
    const offset = 200;
    const isScrollNearBottom = window.innerHeight + currentScrollY >= document.body.offsetHeight - offset;
    const isScrollingDown = currentScrollY > prevScrollY;
    const isNotAtTop = currentScrollY > 0;

    if (isScrollNearBottom && isScrollingDown && isNotAtTop) {
      fetchData();
    }

    setPrevScrollY(currentScrollY);
  }, 0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollY]);

  return (
    <>
      <Container>
        <SEO
          title={`Hyperhuman | Workouts`}
          description=''
          pathname={location.pathname}
          name={''}
          url={'https://hyperhuman.cc/workouts'}
          locale={locale}
        />
        <Content>
          <WorkoutsWrapper>
            <WorkoutsList>
              {workouts.length > 0 &&
                workouts.map((workout, index) => {
                  return (
                    <WorkoutCard
                      key={index}
                      shouldPause={playingVideoIndex !== index}
                      index={index}
                      workout={workout}
                      onVideoPlay={handleVideoPlay}
                    ></WorkoutCard>
                  );
                })}
            </WorkoutsList>
          </WorkoutsWrapper>
        </Content>
      </Container>
    </>
  );
};
export default WorkoutsPage;

const WorkoutsWrapper = styled.div`
  margin-top: 30px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 94px;
  }
`;

const WorkoutsList = styled.div`
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin: 0 auto;
    margin-bottom: 100px;
  }
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Content = styled.div`
  transition: all 150ms ease-in;
`;

const Container = styled.div`
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 1077px;
    margin: 0 auto;
    padding: 0 24px;
  }
  margin: 0px 24px 94px 24px;
  background-color: ${WHITE};
`;
